import FlashLib from 'flashlib';
import { store, storeObserver } from '../index';
import selectors from '../redux/game/selectors';

export default class ControllerLevelPrize extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this.level = parseInt(this.name.replace('level_', ''));

    this.label = this.getChildByName('label');
    storeObserver.addListener(selectors.getLevel, this.onLevelChanged);
    const winAmount = storeObserver.addListenerAsNewAndGetValue(selectors.getWinAmountForStep(this.level), this.onWinAmountChanged);
    this.onWinAmountChanged(winAmount);
  }

  onLevelChanged = (level) => {
    this.visible = this.level >= level;
  };

  onWinAmountChanged = (value) => {
    this.label.text = `${value.toFixed(selectors.getCurrencyInfo(store.getState()).decimal)} ${this.currencyType()}`;
  };

  currencyType() {
    return selectors.getCurrencyType(store.getState());
  }
}

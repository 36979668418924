import Event from '../utils/event';
import SoundManager from './SoundManager';

export const eSoundType = {
  EST_BIG_WIN: 'bigWin',
  EST_CLICK: 'click',
  EST_COINS: 'coins',
  EST_LOSE: 'lose',
  EST_OPEN: 'open',
  EST_SFX: 'sfx',
  EST_WIN: 'win',
};

class ControllerSounds {

  constructor() {
    this.assetsList = [
      { id: eSoundType.EST_BIG_WIN, src: 'assets/sounds/bigWin.mp3' },
      { id: eSoundType.EST_CLICK, src: 'assets/sounds/click.mp3' },
      { id: eSoundType.EST_COINS, src: 'assets/sounds/coins.mp3' },
      { id: eSoundType.EST_LOSE, src: 'assets/sounds/lose.mp3' },
      // { id: eSoundType.EST_OPEN, src: 'assets/sounds/open.mp3' },
      { id: eSoundType.EST_SFX, src: 'assets/sounds/sfx.mp3', loop: true },
      { id: eSoundType.EST_WIN, src: 'assets/sounds/win.mp3' },
    ];

    this.volume = 0.25;
    this.muted = false;

    this.onSoundSettingsChanged = new Event();
  }

  startLoading() {
    for (const value of this.assetsList) {
      PIXI.Loader.shared.add(value.id, value.src);
    }
  }

  playSound(type) {
    if (this.muted) return;
    return SoundManager.play(type, 0.25, false);
  }

  muteSounds() {
    this.muted = true;
    SoundManager.muteGroup('default', true);
    this.onSoundSettingsChanged.call();
  }

  unmuteSounds() {
    SoundManager.muteGroup('default', false);
    this.muted = false;
    this.onSoundSettingsChanged.call();
  }

  playSFX = () => {
    const sound = SoundManager.getSound(eSoundType.EST_SFX);
    if (sound.isPlaying) return;
    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
    SoundManager.play(eSoundType.EST_SFX, this.volume, true);
  };

  pauseSFX() {
    SoundManager.pause(eSoundType.EST_SFX, this.volume, true);
    document.removeEventListener('mousedown', this.playSFX);
    document.removeEventListener('touchstart', this.playSFX);
  }
}

export default new ControllerSounds();

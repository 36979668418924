import FlashLib from 'flashlib';
import { storeObserver } from '../index';
import selectors from '../redux/game/selectors';

export default class ControllerLevelSelector extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.visible = false;

    this.index = parseInt(this.name.replace('level_', ''));

    const currentLevel = storeObserver.addListenerAndGetValue(selectors.getLevel, this.onLevelChanged);
    this.visible = currentLevel === this.index;
  }

  onLevelChanged = (data) => {
    this.visible = data === this.index;
  }
}

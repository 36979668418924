export default {
  CANVAS_WIDTH_LANDSCAPE: 1920,
  CANVAS_HEIGHT_LANDSCAPE: 1080,

  CANVAS_WIDTH_PORTRAIT: 1920,
  CANVAS_HEIGHT_PORTRAIT: 1080,

  SAFE_WIDTH_LANDSCAPE: 460,
  SAFE_HEIGHT_LANDSCAPE: 1080,

  SAFE_WIDTH_PORTRAIT: 460,
  SAFE_HEIGHT_PORTRAIT: 1080

}

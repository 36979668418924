import eAnimationType from './eAnimationType';

class AnimationsLoader {
  constructor() {
    this.animationsData = [
      { type: eAnimationType.EAT_COINS, src: 'assets/animations/coins/oc coins.json' },
      // { type: eAnimationType.EAT_SPINNER, src: 'assets/animations/spinner/spinner.json' },
      { type: eAnimationType.EAT_CELL, src: 'assets/animations/cell/fish and pearl.json' },
    ];
  }

  startLoading(app, onComplete) {
    this.onLoadingComplete = onComplete;
    this.app = app;
    for (let item of this.animationsData) {
      app.loader.add(item.type, item.src);
    }
    app.loader.load(this.onAnimationLoaded);
  }

  onAnimationLoaded = (_, resources) => {
    this.resources = resources;
    this.onLoadingComplete();
  };

  createAnimation(type) {
    return new PIXI.spine.Spine(this.resources[type].spineData);
  }
}

export default new AnimationsLoader();

class ControllerMock {
  constructor() {
    this.field = [];
    this.currentLevel = 0;
    this.path = [];
  }

  requestConfig = async () => {
    return {
      bet_amount: 0.00000001,
      coefs: [1.3, 1.7, 2, 3, 4, 5, 7],
      recover: false,
      currency: 'DEMO',
      balance: 100,
      statistic: '',
      steps: -1,
      ticketId: '',
      bet_min: 0.00000001,
      bet_max: 0.0000001,
      decimal: 8,
    }
  };

  requestNewGame = async () => {
    this.currentLevel = 0;
    this.path = [];
    this.field = [
      this.getRandLevel(),
      this.getRandLevel(),
      this.getRandLevel(),
      this.getRandLevel(),
      this.getRandLevel(),
      this.getRandLevel(),
      this.getRandLevel(),
    ];
    console.log(this.field);
    return {
      payout: [1.3, 1.7, 2, 3, 4, 5, 7],
      stat: '',
    };
  };

  requestFieldOpen = async (index) => {
    this.path.push(index);
    const isWin = this.field[this.currentLevel][index] === 1;
    const result = {
      is_win: isWin,
      path: this.path,
      level: this.field[this.currentLevel],
      steps: this.currentLevel,
      field: isWin ? [] : this.field,
    };
    this.currentLevel = Math.min(this.currentLevel + 1, 6);
    return result;
  };

  requestTakeGame = async () => {
    return {
      is_win: true,
      level: this.field[this.currentLevel],
      steps: this.currentLevel,
      field: this.field,
      path: this.path,
    }
  };

  getRandLevel = () => {
    const result = [1, 1, 1, 1];
    const randomLose = Math.round(Math.random() * 3);
    result[randomLose] = 0;
    return result;
  };
}

export default new ControllerMock();

import ControllerPopup from '../base/controllerPopup';
import eModalType from './eModalType';
import { store } from '../../index';
import selectors from '../../redux/game/selectors';
import { greenStroke, greenStrokeShadow } from '../../fontStyles';

export default class ControllerPopupWin extends ControllerPopup {

  constructor(data, displayData) {
    super(data, displayData);

    this.type = eModalType.EMT_WIN_REGULAR;
    this.initListener();

    this.labelCoef = this.getChildByName('labelCoef');

    this.labelAmount = this.getChildByName('labelAmount');
  }

  onModalDataChanged = (data) => {
    this.visible = data.visible;

    if (data.visible) {
      let state = store.getState();
      let lastWin = selectors.getLastWin(state);
      let currencyType = selectors.getCurrencyType(state);
      let currencyInfo = selectors.getCurrencyInfo(state);
      this.labelCoef.text = '×' + lastWin.win_coef;
      this.labelAmount.text = `${lastWin && lastWin.win_amount.toFixed(currencyInfo.decimal)} ${currencyType}`;
      setTimeout(() => this.onCloseClicked(), 2000);
    }
  };
}

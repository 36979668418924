import './import';
import '../css/index.css'
import 'pixi-spine';
import { createStore } from 'redux';
import reducer from './redux/reducers';
import StoreObserver from './redux/storeObserver';
import Game from './controllers/controllerGame';
import ControllerPopupRulesHTML from './controllers/popups/popupRulesHTML';
import ControllerPopupBigWinHTML from './controllers/popups/popupBigWinHTML';
import OPWrapperService from './libs/op-wrapper-service';
import actionsModals from './redux/modals/actions';
import eModalType from './controllers/popups/eModalType';
import en from '../assets/i18n/en.json';
import enSocial from '../assets/i18n/en-social.json';

export const store = createStore(reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const storeObserver = new StoreObserver(store);

new ControllerPopupRulesHTML();
new ControllerPopupBigWinHTML();
new OPWrapperService({ buttons: [
    {
      type: 'rules',
      styles: ['rules'],
      onClick: () => {
        store.dispatch(actionsModals.showModal({ type: eModalType.EMT_RULES }))
      }
    }
  ],
  bundle: 'luckyocean',
  localizationBundle: 'luckyocean',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
});

new Game();

import * as PIXI from 'pixi.js';
import FlashLib from 'flashlib';
import { requestConfig } from '../api/rest';
import SoundManager from '../sounds/SoundManager';
import ControllerButtonPlay from './buttonPlay';
import WebFont from 'webfontloader';
import ControllerBetSelector from './controllerBetSelector';
import actionsModals from '../redux/modals/actions';
import eModalType from './popups/eModalType';
import { store } from '../index';
import ControllerSounds from '../sounds/controllerSounds';
import AnimationsLoader from './animations/animationsLoader';
import ControllerButtonSound from './buttonSoundMute';
import ControllerStartScreen from './controllerStartScreen';
import ControllerTextField from './textField/ControllerTextField';
import EMAdapter from '../platforms/everyMatrix/adapter';
import Config from './Config';

export default class Game {
  constructor() {
    this.app = null;
    this.compiled = false;

    this.fontsLoaded = false;
    this.assetsLoaded = false;
    this.configReceived = false;
    this.animationsLoaded = false;

    PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;

    FlashLib.TextField = ControllerTextField;
    EMAdapter.init();

    this.start();
  }

  start() {
    this.container = document.getElementById('container');
    this.applicationOptions = {
      // antialias: true,
      // backgroundColor: 0x6fc1f6,
      // legacy: true,
      resolution: window.devicePixelRatio < 2 ? window.devicePixelRatio : 2,
    };
    this.gameSize = {
      width: 1920,
      height: 1080
    };

    this.app = new PIXI.Application(this.applicationOptions);
    this.scaleData = {
      app: this.app,
      gameSize: this.gameSize
    };
    this.app.view.id = 'gameCanvas';
    this.container.appendChild(this.app.view);

    ControllerSounds.startLoading();
    this.loadAssets();
    this.loadFonts();



    AnimationsLoader.startLoading(this.app, () => {
      console.log('Animations loaded');
      this.animationsLoaded = true;
      this.tryInitGame();
    });

    requestConfig()
      .then(() => {
        console.log('Config received');
        this.configReceived = true;
        this.tryInitGame();
      });
  }

  loadAssets() {
    PIXI.Loader.shared.baseUrl = './';
    PIXI.Loader.shared.add('FlashLibAssets', this.compiled ? 'FlashLibAssetsCompiled.json' : 'FlashLibAssets.json', 'json');
    PIXI.Loader.shared.once('complete', () => {
      console.log('Assets loaded');
      this.assetsLoaded = true;
      this.tryInitGame();
    }, this);
    PIXI.Loader.shared.on('progress', (data) => {
      OPPreloader.setProgress(data.progress);
      EMAdapter.loadProgress(data.progress);
    });
    PIXI.Loader.shared.on('error', this.onLoadingError.bind(this));
    PIXI.Loader.shared.load();
  }

  loadFonts() {
    WebFont.load({
      custom: {
        families: [
          'Anja Eliane',
          'Ubuntu bold',
          'Inter Extra Bold',
          'Inter Semi Bold'
        ],
      },
      fontinactive: console.error,
      active: () => {
        console.log('Fonts loaded');
        this.fontsLoaded = true;
        this.tryInitGame();
      },
      inactive: this.onLoadingError.bind(this),
      timeout: 60000
    });
  }

  onLoadingError(err) {
    console.error(err);
    window.OPWrapperService.showError(window.OPWrapperService.errors.ASSETS_ERROR.CODE);
    PIXI.Loader.shared.reset();
  }
  tryInitGame() {
    if (this.fontsLoaded &&
      this.assetsLoaded &&
      this.configReceived &&
      this.animationsLoaded) {
      // ControllerSounds.playSFX();
      window.OPPreloader.hide();
      this.initGame();
    }
  }

  initGame() {
    EMAdapter.loadCompleted();
    SoundManager.init();
    const main = FlashLib.createItemFromLibrary('main', 'FlashLib');
    const mainScreen = main.getChildByName('mainScreen');
    this.app.stage.addChild(main);
    const buttons = mainScreen.getChildByName('buttons');

    new ControllerStartScreen(main);
    new ControllerButtonPlay(mainScreen);
    new ControllerButtonSound(buttons);
    // new ControllerBalanceView(main);
    new ControllerBetSelector(buttons.getChildByName('betSelector'));

    buttons.getChildByName('buttonInfo').addListener('pointertap', () => {
      store.dispatch(actionsModals.showModal({ type: eModalType.EMT_RULES }))
    });
    window.OPWrapperService.ScaleManager.init(this.scaleData, Config);
  }

}

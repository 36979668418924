import ControllerPopupHTML from '../base/controllerPopupHTML';
import eModalType from './eModalType';
import { store } from '../../index';
import selectors from '../../redux/game/selectors';

export default class Controller extends ControllerPopupHTML {
  constructor() {
    super();
    this.type = eModalType.EMT_WIN_BIG;
    this.element = document.getElementById(this.type);
    this.initListener();

    this.labelCoef = this.element.getElementsByClassName('x-sum')[0];
    this.labelAmount = this.element.getElementsByClassName('sum')[0];
    this.labelCurrency = this.element.getElementsByClassName('currency')[0];

    this.title = this.element.getElementsByClassName('pop-up__big-win-title-light')[0];
    this.titleShadow = this.element.getElementsByClassName('pop-up__big-win-title-bg')[0];
    this.congratulations = this.element.getElementsByClassName('congratulations')[0];
    this.youLucky = this.element.getElementsByClassName('youLucky')[0];
  }

  onModalDataChanged = (data) => {
    super.onModalDataChanged(data);

    if (data.visible) {
      this.checkLocalization();
      let state = store.getState();
      let lastWin = selectors.getLastWin(state);
      let currencyType = selectors.getCurrencyType(state);
      let currencyInfo = selectors.getCurrencyInfo(state);
      this.labelCoef.innerHTML = '×' + lastWin.win_coef;
      this.labelAmount.innerHTML = `${lastWin && lastWin.win_amount.toFixed(currencyInfo.decimal)}`;
      this.labelCurrency.innerHTML = ` ${currencyType}`;
    }
  };

  checkLocalization() {
    const LocalizationManager = window.OPWrapperService.localizations;
    this.title.innerHTML = LocalizationManager.getLocalizedText('popup_big_win_title');
    this.titleShadow.setAttribute('data-text', LocalizationManager.getLocalizedText('popup_big_win_title'));
    this.congratulations.innerHTML = LocalizationManager.getLocalizedText('popup_big_win_grats');
    this.youLucky.innerHTML = LocalizationManager.getLocalizedText('popup_big_win_you_lucky');
    this.closeButton.innerHTML = LocalizationManager.getLocalizedText('popup_big_win_collect');
  }
  onCloseClicked() {
    super.onCloseClicked();
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
}

import { createActions } from 'redux-actions';

export default createActions(
  {
    SET_DATA: (type) => type,
    SET_DATA_FIELD_OPEN_WIN: (data) => data,
    SET_DATA_GAME_END: (data) => data,
    SET_NEW_GAME: (data) => data,
    SET_BET_AMOUNT: (data) => data,
    INCREMENT_BET: (data) => data,
    DECREMENT_BET: (data) => data,
    SET_MIN_BET: (data) => data,
    SET_MAX_BET: (data) => data,
    INIT_HIGHLIGHTS: (data) => data,
    ADD_HIGHLIGHT: (data) => data,
    OPEN_OTHER_CELLS: (data) => data,
    SET_CURRENCY_INFO: (data) => data,
    DECREASE_BALANCE: (data) => data,
  },
  {
    prefix: 'gameClover',
    namespace: '.',
  },
);

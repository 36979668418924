import { store, storeObserver } from '../../index';
import selectors from '../../redux/modals/selectors';
import actions from '../../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../../sounds/controllerSounds';

export default class ControllerPopupHTML {
  constructor() {
    this.type = 'base';
    this.element = ''; // need set in inherit class
    this.onCloseClicked = this.onCloseClicked.bind(this);
  }

  initListener = () => {
    storeObserver.addListenerAsNew(selectors.getModalData(this.type), this.onModalDataChanged.bind(this));
    this.closeButton = this.element.getElementsByClassName('closeButton')[0];
    this.closeButton.addEventListener('click', this.onCloseClicked);
  };

  onModalDataChanged(data){
    const value = data.visible ? 'block' : 'none';
    this.element.style.setProperty('display', value);
  };

  onCloseClicked() {
    ControllerSounds.playSound(eSoundType.EST_CLICK);
    store.dispatch(actions.closeModal({ type: this.type }))
  }
}

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/ROUNDS_BLACK.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/Ubuntu/Ubuntu-Bold.eot");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/fonts/Ubuntu/Ubuntu-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/fonts/Ubuntu/Ubuntu-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/fonts/Ubuntu/Ubuntu-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/fonts/Inter/Inter-SemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/fonts/Inter/Inter-SemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../assets/fonts/Inter/Inter-SemiBold.ttf");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../assets/fonts/Inter/Inter-ExtraBold.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../assets/fonts/Inter/Inter-ExtraBold.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../assets/fonts/Inter/Inter-ExtraBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
// Module
exports.push([module.id, "@font-face {\n    font-family: 'Anja Eliane';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    /*src: url('../assets/fonts/AnjaElianeNornal.eot?#iefix') format('embedded-opentype'),*/\n    /*    url('../assets/fonts/AnjaElianeNornal.woff2') format('woff2'),*/\n    /*    url('../assets/fonts/AnjaElianeNornal.woff') format('woff'),*/\n    /*    url('../assets/fonts/AnjaElianeNornal.ttf') format('truetype'),*/\n    /*    url('../assets/fonts/AnjaElianeNornal.svg#AnjaElianeNornal') format('svg');*/\n    font-weight: normal;\n    font-style: normal;\n    font-display: block;\n}\n\n@font-face {\n    font-family: 'Ubuntu bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('embedded-opentype'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff'),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('truetype');\n    font-weight: bold;\n    font-style: normal;\n    font-display: block;\n}\n\n@font-face {\n    font-family: 'Inter Semi Bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ");\n    font-display: block;\n}\n\n@font-face {\n    font-family: 'Inter Extra Bold';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + "),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ");\n    font-display: block;\n}\n", ""]);
// Exports
module.exports = exports;

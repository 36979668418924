import { storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import FlashLib from 'flashlib';

export default class ControllerBalanceView extends FlashLib.MovieClip {

  constructor(data, displayData) {
    super(data, displayData);
    this.labelBalance = this.getChildByName('label');

    this.balance = storeObserver.addListenerAndGetValue(selectors.getBalance, this.onBalanceChanged);
    this.currencyInfo = storeObserver.addListenerAndGetValue(selectors.getCurrencyInfo, this.onCurrencyInfoChanged);

    this.labelBalance.text = `${this.balance.toFixed(this.currencyInfo.decimal)} ${this.currencyInfo.currency}`;
  }

  onBalanceChanged = (value) => {
    this.balance = value;
    this.labelBalance.text = `${this.balance.toFixed(this.currencyInfo.decimal)} ${this.currencyInfo.currency}`;
  };

  onCurrencyInfoChanged = (value) => {
    this.currencyInfo = value;
    this.onBalanceChanged(this.balance);
  };
}

import eModalType from './eModalType';
import ControllerPopupHTML from '../base/controllerPopupHTML';

export default class Controller extends ControllerPopupHTML {
  constructor() {
    super();
    this.type = eModalType.EMT_RULES;
    this.element = document.getElementById(this.type);
    this.title = this.element.getElementsByClassName('pop-up__title')[0];
    this.description = this.element.getElementsByClassName('rulesDesc')[0];
    this.initListener();
  }

  onModalDataChanged(data) {
    super.onModalDataChanged(data);

    const LocalizationManager = window.OPWrapperService.localizations;
    this.title.innerHTML = LocalizationManager.getLocalizedText('rules_title');
    this.description.innerHTML = LocalizationManager.getLocalizedText('rules_description')
  }
}

import FlashLib from 'flashlib';
import ControllerSounds from '../../sounds/controllerSounds';
import { eSoundType } from '../../sounds/controllerSounds';
import { greenShadow } from '../../fontStyles';

export default class ControllerButton extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);

    //todo: use or remove this
    this.actions = {};

    this.text = this.getChildByName('label');

    if (this.text) {
      this.text.anchor.set(0);
      this.text.style = {
        ...this.text.style,
        ...greenShadow,
      }
    }

    this.init();
    this.addListeners();
  }

  init() {
    this.interactive = true;
    this.buttonMode = true;
  }

  addListener(type, callback) {
    this.on(type, callback, this);
  }

  enable() {
    this.interactive = true;
    this.buttonMode = true;
    this.goToFrame(1);
    if(this.text)
      this.text.style = {
        ...this.text.style,
        ...greenShadow
      };
  }

  disable() {
    this.interactive = false;
    this.buttonMode = false;
    this.goToFrame(4);
    if(this.text)
      this.text.style = {
        ...this.text.style,
        dropShadowColor: 'grey'
      };
  }

  setText(text) {
    this.text.text = text;
  }

  addListeners() {
    this.on('mouseover', this.onEvent, this);
    this.on('mouseout', this.onEvent, this);
    this.on('mousedown', this.onEvent, this);
    this.on('mouseup', this.onEvent, this);

    // this.on('pointerover', this.onEvent, this);
    // this.on('pointerout', this.onEvent, this);
    this.on('pointerdown', this.onEvent, this);
    this.on('pointerup', this.onEvent, this);
    this.on('pointercancel', this.onEvent, this);
    this.on('pointerupoutside', this.onEvent, this);

    // this.on('click', this.onEvent, this);
    this.on('pointertap', this.onEvent, this);
  }

  onEvent($data) {
    switch ($data.type) {
      case 'mouseover':
        this.onOver();
        break;
      case 'pointerupoutside':
      case 'mouseout':
        this.onOut();
        break;
      case 'pointerdown':
        this.onDown();
        break;
      case 'pointerup':
        this.onUp();
        break;
      case 'pointertap':
        this.onClick();
        break;
      default:
        console.log('Unhandled event:', $data.type);
        break;
    }

    let actionData = this.actions[$data.type];
    if (actionData) {
      actionData.callback.call(actionData.context);
    }
  }

  onOver() {
    this.goToFrame(2);
  }

  onOut() {
    this.goToFrame(1);
  }

  onDown() {
    this.goToFrame(3);
  }

  onUp() {
    this.goToFrame(2);
  }

  onClick() {
    ControllerSounds.playSound(eSoundType.EST_CLICK);
  }
}

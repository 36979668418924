import FlashLib from 'flashlib';
import { store, storeObserver } from '../index';
import selectors from '../redux/game/selectors';
import { requestFieldOpen } from '../api/rest';
import { eCellState, eGameState } from '../redux/game/reducer';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import eAnimationType from './animations/eAnimationType';
import AnimationsLoader from './animations/animationsLoader';

export default class ControllerFieldCell extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);
    this.interactive = true;

    this.goToFrame(1);
    this.level = parseInt(this.name.match(/\d+_/)[0]);
    this.index = parseInt(this.name.match(/\d+$/)[0]);

    this.pathGlow = this.getChildByName('pathGlow');
    this.pathGlow.visible = false;

    this.on('pointertap', this.onCellClicked, this);
    this.initCellAnimation();

    this.cachedData = {};
    let state = storeObserver.addListenerAsNewAndGetValue(selectors.getFieldCellInfo(this.level, this.index), this.onStateChanged);
    this.onStateChanged(state);
  }

  initCellAnimation() {
    this.animation = AnimationsLoader.createAnimation(eAnimationType.EAT_CELL);
    // this.animation.x = this.width / 2;
    // this.animation.y = this.height / 2;
    // this.removeChildAt(0);
    this.addChildAt(this.animation, 0);
    this.animation.state.addListener({
      complete: this.onAnimationComplete
    });
  }

  onAnimationComplete() {

  }

  showAnimation(type) {
    this.animation.state.setAnimation(0, type, false);
  }

  onCellClicked = () => {
    const state = store.getState();
    if (selectors.getGameState(state) !== eGameState.EGS_READY_TO_PLAY &&
      selectors.getLevel(state) === this.level) {
      ControllerSounds.playSound(eSoundType.EST_CLICK);
      requestFieldOpen(this.index);
    }
  };

  onStateChanged = (data) => {
    if (data.isClosed) {
      this.animation.update(0);
      this.animation.skeleton.setToSetupPose();
      this.animation.state.tracks = [];
    } else if (data.isClosed !== this.cachedData.isClosed || data.state !== this.cachedData.state) {
      const animation = data.state === eCellState.ECS_WIN ? 'pearl' : 'fish';
      this.showAnimation(animation);
    }

    this.pathGlow.visible = data.isPath && !data.isClosed;

    this.cachedData = data;
  }
}

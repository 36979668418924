import Sound from 'pixi-sound';
import SoundGroup from './SoundGroup';

export default new class SoundManager {
  constructor() {
    this.sound = null;
    this._soundsConfig = {};
    this._groups = {};

    this._blurMutedState = {};

    this._masterVolume = 1;

    this.addListeners();

    this.addNewSoundGroup('default');
  }

  init() {
    this.sound = Sound;
  }

  addListeners() {
    window.addEventListener('visibilitychange', this._onVisibilityChange.bind(this));
  }

  _onVisibilityChange() {
    if (document.visibilityState === 'visible') {
      this.unmuteAll();
    } else {
      this.muteAll();
    }
  }

  getSoundConfigData(alias) {
    return this.soundsConfig[alias] || {};
  }

  unmuteAll() {
    for (let param in this.groups) {
      if (this._blurMutedState.hasOwnProperty(param)) {
        this.muteGroup(param, this._blurMutedState[param]);
      } else {
        this.muteGroup(param, false);
      }
    }
    this._blurMutedState = {};
  }

  muteAll() {
    this._blurMutedState = {};
    for (let param in this.groups) {
      this._blurMutedState[param] = this.groups[param].muted;
      this.muteGroup(param, true);
    }
  }

  play(alias, volume = 1, loop = false, groupName = 'default') {
    let sound = this.getSound(alias);
    if (sound) {
      let config = this.getSoundConfigData(alias);
      let vol = this._masterVolume * config.hasOwnProperty('volume') ? config.volume : volume;
      let loo = config.hasOwnProperty('loop') ? config.loop : loop;
      let gro = config.hasOwnProperty('group') ? config.group : groupName;

      let instance = sound.play({ loop: loo, volume: vol });
      sound.baseVolume = volume;
      this.addSoundToGroup(sound, alias, gro);
      return instance;
    } else {
      console.error('Can\'t find sound: ', alias);
    }
  }

  stop(alias, groupName = 'default') {
    let sound = this.getSound(alias);
    const group = this.getGroup(groupName);
    group.remove(alias);
    if (sound) {
      let instance = sound.stop();
      return instance;
    }
  }

  pause(alias) {
    let sound = this.getSound(alias);
    if (sound.isPlaying) {
      this.sound.pause(alias);
    }
  }

  resume(alias) {
    let sound = this.getSound(alias);
    if (sound && !sound.isPlaying) {
      this.sound.resume(alias);
    }
  }

  muteGroup(groupName, value) {
    let group = this.getGroup(groupName);
    if (group) {
      if (value === undefined) {
        group.switchMuted();
      } else {
        group.muted = value;
      }
    }
  }

  volumeGroup(groupName, value) {
    let group = this.getGroup(groupName);
    if (group) {
      group.volume = value;
    }
  }

  getSound(alias) {
    return this.sound.find(alias);
  }

  addNewSoundGroup(groupName) {
    if (!this.groups.hasOwnProperty(groupName)) {
      this._groups[groupName] = new SoundGroup(groupName);
    }
  }

  addSoundToGroup(sound, alias, groupName) {
    let group = this.getGroup(groupName);
    group.addSound(sound, alias);
  }

  getGroup(groupName) {
    if (!this.groups.hasOwnProperty(groupName)) {
      this.addNewSoundGroup(groupName);
    }
    return this.groups[groupName];
  }

  get groups() {
    return this._groups;
  }

  set soundsConfig(value) {
    this._soundsConfig = value;
  }

  get soundsConfig() {
    return this._soundsConfig;
  }

  set masterVolume(value) {
    this._masterVolume = value;
  }

  get masterVolume() {
    return this._masterVolume;
  }
};

import ControllerSounds from '../../sounds/controllerSounds';

export default class ControllerButtonSound {
  constructor(container) {
    this.buttonOn = container.getChildByName('buttonMute');
    this.buttonOn.on('pointertap', this.onButtonOnClicked);
    this.buttonOff = container.getChildByName('buttonUnmute');
    this.buttonOff.on('pointertap', this.onButtonOffClicked);
    this.buttonOff.visible = false;
    ControllerSounds.onSoundSettingsChanged.add(this.onSoundSettingsChanged.bind(this))
  }

  onButtonOnClicked = () => {
    this.buttonOn.visible = false;
    this.buttonOff.visible = true;
    if (!ControllerSounds.muted) {
      ControllerSounds.muteSounds();

    }
  };

  onButtonOffClicked = () => {
    this.buttonOn.visible = true;
    this.buttonOff.visible = false;
    if (ControllerSounds.muted) {
      ControllerSounds.unmuteSounds();
    }
  };

  onSoundSettingsChanged() {
    const state = ControllerSounds.muted;
    this.buttonOff.visible = state;
    this.buttonOn.visible = !state;
  }
}

export const greenShadow = {
  dropShadow: true,
  dropShadowAlpha: 0.7,
  dropShadowAngle: 1.3,
  dropShadowColor: "#016f00",
  dropShadowDistance: 6,
};


// legacy
export const greenStroke = {
  stroke: '#3c6e24',
  strokeThickness: 8,
};

export const greenStrokeShadow = {
  stroke: '#3c6e24',
  strokeThickness: 8,
  dropShadow: true,
  dropShadowAngle: 1.5,
  dropShadowBlur: 1,
  dropShadowColor: '#1d3d0d',
  dropShadowDistance: 10,
};

export const yellowStrokeGradient = {
  letterSpacing: 10,
  lineJoin: 'round',
  miterLimit: 0,
  stroke: '#4e0600',
  strokeThickness: 10,
  fill: [
    "#fab406",
    "#f7f6af",
    "#fab406",
    "#fffb00"
  ]
};


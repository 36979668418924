const getBalance = (state) => state.game.balance;
const getBetAmount = (state) => state.game.betAmount;
const getBetIndex = (state) => state.game.bets.indexOf(state.game.betAmount);
const getGameState = (state) => state.game.gameState;
const getCurrencyType = (state) => state.game.currency;
const getStatistic = (state) => state.game.statistic;
const getActivities = (state) => state.game.activity;
const getLastWin = (state) => state.game.activity.myBets[0];
const getWinAmount = (state) => state.game.betAmount * state.game.rulesCoefs[state.game.steps];
const getProvablyFair = (state) => state.game.provablyFair;
const getCurrencyInfo = (state) => state.game.currencyInfo;
const getTicketId = (state) => state.game.ticketId;
const getBetMinMax = (state) => ({ min: state.game.betMin, max: state.game.betMax });
const getLevel = (state) => state.game.level;

const getFieldCellInfo = (level, index) => (state) => state.game.fieldInfo[level][index];
const getWinAmountForStep = (step) => (state) => state.game.betAmount * state.game.rulesCoefs[step];

export default {
  getBalance,
  getBetAmount,
  getBetIndex,
  getGameState,
  getCurrencyType,
  getFieldCellInfo,
  getStatistic,
  getActivities,
  getWinAmount,
  getLastWin,
  getCurrencyInfo,
  getProvablyFair,
  getTicketId,
  getBetMinMax,
  getLevel,
  getWinAmountForStep,
}

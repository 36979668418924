import actions from '../redux/game/actions';
import actionsModals from '../redux/modals/actions';
import ControllerSounds, { eSoundType } from '../sounds/controllerSounds';
import ControllerMock from './controllerMock';

const URL = '/game_request';

import { store } from '../index';
import eModalType from '../controllers/popups/eModalType';
import { getUrlParams } from '../utils/url';
import selectors from '../redux/game/selectors';

const urlParams = getUrlParams(window.location.search);
const devEnvironments = [/localhost/, /0\.0\.0\.0/, /192\.168\./];
if (devEnvironments.some(regexp => regexp.test(window.location.origin))) {
  if (!Object.keys(urlParams).length) {
    getSession();
  }
}

const enableMock = false;
export const requestConfig = async () => {
  const responseInit = await request('get', '/game_init');
  if (!responseInit) return;

  await window.OPWrapperService.init({
    partnerConfig: responseInit.partner_config,
    language: responseInit.lang,
    currencyInfo: {
      currency: responseInit.currency,
      decimals: responseInit.decimals,
    },
  });

  const params = {
    id: 'config',
  };

  const response = enableMock ?
    await ControllerMock.requestConfig() :
    await request('POST', URL, params).catch(console.error);
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  store.dispatch(actions.setData(response));
};

export const requestNewGame = async (betIndex) => {
  const params = {
    id: 'new_game',
    betIndex,
  };

  let response = enableMock ?
    await ControllerMock.requestNewGame() :
    await request('POST', URL, params)
      .catch((error) => {
        if (error.message === 'Token is NULL') {

        } else if (error.code === 6324 || error.code === 6343) {

        }

        throw error;
      });

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setNewGame(response));
};

export const requestFieldOpen = async (index) => {
  const params = {
    id: 'open',
    index,
  };

  let response = enableMock ?
    await ControllerMock.requestFieldOpen(index) :
    await request('POST', URL, params);

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  if (response.is_win) {
    store.dispatch(actions.setDataFieldOpenWin(response));
    if (response.steps === 6) {
      requestTakeGame(store.dispatch);
      ControllerSounds.playSound(eSoundType.EST_BIG_WIN);
    } else {
      ControllerSounds.playSound(eSoundType.EST_WIN);
    }
  } else {
    window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    store.dispatch(actions.setDataGameEnd(response));
    ControllerSounds.playSound(eSoundType.EST_LOSE);
  }
};

export const requestTakeGame = async () => {
  const params = {
    id: 'collect',
  };

  const response = enableMock ?
    await ControllerMock.requestTakeGame() :
    await request('POST', URL, params);

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  let state = store.getState();
  window.OPWrapperService.ControllerStatistic.win = selectors.getWinAmount(state);
  window.OPWrapperService.realityCheck.blockedRealityCheck = false;

  store.dispatch(actions.setDataGameEnd(response));
  store.dispatch(actions.openOtherCells());

  ControllerSounds.playSound(eSoundType.EST_COINS);
  let modalType;
    if (response.steps === 6){
      modalType = eModalType.EMT_WIN_BIG;
    } else {
      modalType = eModalType.EMT_WIN_REGULAR;
      window.OPWrapperService.freeBetsController.updateTotalWin();
    }
  store.dispatch(actionsModals.showModal({ type: modalType }));
};

async function getSession() {
  try {
    const raw = await fetch('https://int.dev.onlyplay.net/integration/request_frame/?game=luckyocean&no_redirect&no_op_launcher', {
      method: 'GET',
    });
    const response = await raw.json();
    if (response.url) {
      const searchParams = response.url.split('?')[1];
      window.location = `${window.location.origin}?${searchParams}`;
    }
  } catch (e) {
    console.error(e);
  }
}

let waitingForResponse = false;
const request = async (method, url, params) => {
  if (waitingForResponse) {
    throw new Error('Already waiting for response.')
  }

  if (!navigator.onLine) {
    window.OPWrapperService.showError(window.OPWrapperService.errors.NO_CONNECTION.CODE);
    throw new Error('No Internet connection.')
  }

  waitingForResponse = true;

  url = `https://${urlParams.api + url}/?sid=${urlParams.sid}&gid=${urlParams.gid}`;
  const options = { method };

  if (params) {
    params.ver = '1.1';

    if (method.toUpperCase() === 'POST') {
      options.body = JSON.stringify(params);
    } else {
      url += `&req=${JSON.stringify(params)}`;
    }
  }

  let response = await fetch(url, options);

  if (response.status === 500) {
    throw new Error('Server Down');
  }

  response = await response.json()
    .catch((error) => {
      console.error(error);
      console.error('Response', response);
      throw error;
    });

  waitingForResponse = false;

  if (!response.success) {
    //9001 - insufficient balance
    window.OPWrapperService.showError(response.code, response.message);
    throw response;
  }
  return response;
};

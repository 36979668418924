import { greenShadow } from '../fontStyles';
import ControllerSounds from '../sounds/controllerSounds';

export default class ControllerStartScreen {
  constructor(main) {
    this.startScreen = main.getChildByName('startScreen');
    this.mainScreen = main.getChildByName('mainScreen');
    this.mainScreen.visible = false;
    this.label = this.startScreen.getChildByName('welcome_title');
    this.label.style = {
      ...this.label.style,
      ...greenShadow,
      letterSpacing: 1,
    };

    this.buttonStart = this.startScreen.getChildByName('buttonStart');
    const labelStart = this.buttonStart.getChildByName('button_start');
    labelStart.style = {
      ...labelStart.style,
      ...greenShadow,
    };

    this.buttonStart.on('pointertap', this.onButtonStartClicked);
  }

  onButtonStartClicked = () => {
    this.startScreen.visible = false;
    this.mainScreen.visible = true;
    ControllerSounds.playSFX();
    window.OPWrapperService.Fullscreen.showButtonIfAndroid(true);
    window.OPWrapperService.freeBetsController.show();
  };
}

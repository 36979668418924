import FlashLib from 'flashlib';
import { store, storeObserver } from '../../index';
import selectors from '../../redux/modals/selectors';
import actions from '../../redux/modals/actions';

export default class ControllerPopup extends FlashLib.MovieClip {
  constructor(data, $displayItemData) {
    super(data, $displayItemData);

    this.visible = false;

    this.type = 'base';
  }

  initListener = () => {
    storeObserver.addListenerAsNew(selectors.getModalData(this.type), this.onModalDataChanged);
  };

  initBlocker = () => {
    // 0 is blocker, 1 - popup background
    this.children[0].interactive = true;
    this.children[1].interactive = true;
    this.children[0].on('pointertap', this.onCloseClicked);
  };

  onModalDataChanged = (data) => {
    this.visible = data.visible;
  };

  onCloseClicked = () => {
    store.dispatch(actions.closeModal({ type: this.type }))
  }
}

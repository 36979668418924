import { store, storeObserver } from '../../index';
import actions from '../../redux/game/actions';
import modalActions from '../../redux/modals/actions';
import eModalType from '../../controllers/popups/eModalType';
import controllerSounds from '../../sounds/controllerSounds';
import selectors from '../../redux/game/selectors';
import { eGameState } from '../../redux/game/reducer';

export default new class Adapter {
  init() {
    const eventManager = window.OPWrapperService.eventManager;
    this.eventManager = eventManager;
    const receiveMessageTypes = eventManager.receiveMessageTypes;
    this.postMessagesTypes = eventManager.postMessageTypes;
    eventManager.add(receiveMessageTypes.RMT_UPDATE_BALANCE, this.updateBalance, this);
    eventManager.add(receiveMessageTypes.RMT_STOP_AUTO_SPINS, this.stopAutoSpins, this);
    eventManager.add(receiveMessageTypes.RMT_SET_AUDIO, this.setAudio, this);
    eventManager.add(receiveMessageTypes.RMT_SHOW_HELP, this.showHelp, this);
    eventManager.add(receiveMessageTypes.RMT_TOGGLE_PAYTABLE, this.togglePaytable, this);

    storeObserver.addListener(selectors.getBetAmount, this.stakeUpdate.bind(this));
    storeObserver.addListener(selectors.getGameState, this.stateUpdated.bind(this));

    controllerSounds.onSoundSettingsChanged.add(this.audioToggle.bind(this));

    this.gameStarted = false;
  }

  // <<<<<<RECEIVE MESSAGES>>>>>
  updateBalance() {

  }

  stopAutoSpins() {

  }

  setAudio(value) {
    if (!value) {
      controllerSounds.pauseSFX();
      controllerSounds.muteSounds();
    } else {
      controllerSounds.playSFX();
      controllerSounds.unmuteSounds();
    }
  }

  showHelp() {

  }

  togglePaytable() {
    const visible = store.getState().modals[eModalType.EMT_RULES].visible;
    if (visible) {
      store.dispatch(modalActions.closeModal({ type: eModalType.EMT_RULES }));
    } else {
      store.dispatch(modalActions.showModal({ type: eModalType.EMT_RULES }));
    }
  }

  // <<<<<<<POST MESSAGES>>>>>>>

  loadProgress(data) {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_PROGRESS, data);
  }

  loadCompleted() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_LOAD_COMPLETED);
    const audioState = controllerSounds.SFXPlaying || !controllerSounds.muted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
    this.stakeUpdate();
  }

  audioToggle() {
    const audioState = controllerSounds.SFXPlaying || !controllerSounds.muted;
    this.eventManager.dispatch(this.postMessagesTypes.PMT_AUDIO_TOGGLE, audioState);
  }

  stakeUpdate() {
    this.eventManager.dispatch(this.postMessagesTypes.PMT_STAKE_UPDATE, store.getState().game.betAmount);
  }

  stateUpdated() {
    const game = store.getState().game;
    if ((game.gameState === eGameState.EGS_CAN_CANCEL || game.gameState === eGameState.EGS_CAN_TAKE)&& !this.gameStarted) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_START);
      this.gameStarted = true;
    }

    if (game.gameState === eGameState.EGS_READY_TO_PLAY) {
      this.eventManager.dispatch(this.postMessagesTypes.PMT_ROUND_END);
      this.gameStarted = false;
    }
  }

  closed() {

  }
}
